<template>
  <!-- 新增系统管理员页面 -->
  <div>
    <!-- 填写表单 -->
    <el-form
      ref="ruleForm"
      :model="ruleForm"
      label-width="120px"
      :rules="rules"
    >
      <el-form-item label="用户名" prop="adminName">
        <el-input
          v-model="ruleForm.adminName"
          onkeyup="value=value.replace(/[^\w\.\/]/ig,'')"
          placeholder="请输入用户名"
          size="mini"
          style="width: 300px"
        ></el-input>
      </el-form-item>
      <el-form-item label="请输入密码" prop="pwd">
        <el-input
          placeholder="请输入密码"
          v-model="ruleForm.pwd"
          onkeyup="value=value.replace(/[^\w\.\/]/ig,'')"
          show-password
          size="mini"
          style="width: 300px"
        ></el-input>
      </el-form-item>
      <el-form-item label="真实姓名" prop="adminTureName">
        <el-input
          v-model="ruleForm.adminTureName"
          placeholder="请输入真实姓名"
          size="mini"
          style="width: 300px"
        ></el-input>
      </el-form-item>
      <el-form-item label="手机号" prop="adminTel">
        <el-input
          v-model.number="ruleForm.adminTel"
          onkeyup="this.value = this.value.replace(/[^\d.]/g,'');"
          placeholder="请输入手机号"
          size="mini"
          style="width: 300px"
        ></el-input>
      </el-form-item>
      <el-form-item label="备注" prop="adminDesc">
        <el-input
          v-model="ruleForm.adminDesc"
          placeholder="备注"
          size="mini"
          style="width: 300px"
        ></el-input>
      </el-form-item>
      <el-form-item label="角色" prop="roles">
        <el-select v-model="ruleForm.roles" placeholder="请选择">
          <el-option
            v-for="item in options"
            :key="item.id"
            :label="item.roleName"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="用户状态" prop="status">
        <el-select v-model="ruleForm.status" placeholder="请选择">
          <el-option
            v-for="item in status"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="">
        <div>
          <el-button type="" @click="getBack">返回</el-button>
          <el-button type="warning" @click="submitForm('ruleForm')"
            >校验</el-button
          >
          <el-button type="primary" @click="goAdd('ruleForm')">保存</el-button>
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      ruleForm: {
        adminName: "", // 用户名
        pwd: "", //密码
        adminTureName: "", // 真实姓名
        adminTel: "", //手机号
        adminDesc: "", //备注
        status: "", //用户状态
        roles: [], //系统角色
        createBy: "", // 创建人ID
      },
      //   角色选择
      options: [],
      // 用户状态
      status: [
        {
          value: 0,
          label: "启用",
        },
        {
          value: 1,
          label: "禁用",
        },
      ],
      //   验证规则
      rules: {
        adminName: [
          { required: true, message: "请输入用户名", trigger: "blur" },
        ],
        pwd: [{ required: true, message: "请输入密码", trigger: "blur" }],
        adminTureName: [
          { required: true, message: "请输入真实姓名", trigger: "blur" },
        ],
        adminTel: [
          { required: true, message: "请输入手机号", trigger: "blur" },
        ],
        roles: [{ required: true, message: "请选择角色", trigger: "blur" }],
        status: [
          { required: true, message: "请选择用户状态", trigger: "blur" },
        ],
      },
    };
  },
  created() {
    this.ruleForm.createBy = JSON.parse(
      sessionStorage.getItem("admin")
    ).adminId;
    // 获取系统角色
    this.axios
      .get("/admin/role/selelctRoleList", {
        pageNumber: 1,
        pageSize: 999,
      })
      .then((res) => {
        console.log(res);
        this.options = res.data.data.records;
        // 数组中包对象的del值为1 就删除
        this.options = this.options.filter((item) => item.del !== 1);
      });
  },
  methods: {
    // 保存

    goAdd(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let a = this.ruleForm.roles;
          this.ruleForm.roles = [];
          this.ruleForm.roles.push(a);
          this.axios
            .post("/admin/admin/addAdmin", this.ruleForm)
            .then((res) => {
              console.log(res);
              if (res.data.code == 200) {
                this.$message({
                  message: res.data.mesg,
                  type: "success",
                });
                this.getBack();
              } else {
                this.$message({
                  message: res.data.mesg,
                  type: "error",
                });
              }
            })
            .catch((err) => {});
        } else {
          this.$message({
            message: "请填写完整数据",
            type: "error",
          });
        }
      });
    },
    //   返回上一页面
    getBack() {
      this.$router.go(-1);
    },

    // 校验
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$message({
            message: "校验通过",
            type: "success",
          });
          return true;
        } else {
          this.$message({
            message: "请填写完整数据",
            type: "error",
          });
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
